import React from "react"
import { graphql } from "gatsby"
//
import { Layout } from "../components/layout"
import { Container } from "../storybook/components/misc/Layouts"
import { renderComponent } from "../utils/components"
import { getTags } from "../utils/seo"

const Page = ({ data }) => {
  const { contentfulPagina, contentfulSite } = data
  const { titulo, sumario, imagem, hero, componentes } = contentfulPagina

  const tags = getTags({ titulo, sumario, imagem, site: contentfulSite })

  return (
    <Layout tags={tags} heroType={hero.__typename}>
      {renderComponent(hero)}
      <Container tw="z-30" id="main-section">
        {componentes?.map((comp, idx) => renderComponent(comp, idx))}
      </Container>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    contentfulPagina(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      titulo
      sumario {
        sumario
      }
      imagem {
        file {
          url
        }
        gatsbyImageData(layout: FIXED, width: 1200, height: 600, resizingBehavior: CROP, quality: 80)
      }
      hero {
        ... on Node {
          __typename
          ... on ContentfulHeroCarousel {
            ...FragmentHeroCarousel
          }
          ... on ContentfulHeroVideoOuImagem {
            ...FragmentHeroVideoOuImagem
          }
          ... on ContentfulHeroTituloSimples {
            ...FragmentHeroTituloSimples
          }
        }
      }
      componentes {
        ... on Node {
          __typename
          ... on ContentfulBlocosInfoGrid {
            ...FragmentBlocosInfoGrid
          }
          ... on ContentfulBlocosTextoEImagemLateral {
            ...FragmentBlocosTextoEImagemLateral
          }
          ... on ContentfulBlocosTextoFormatado {
            ...FragmentTextBlock
          }
          ... on ContentfulBlocosInfoList {
            ...FragmentBlocosInfoList
          }
          ... on ContentfulBlocosBannerTexto {
            ...FragmentBlocosBannerTexto
          }
          ... on ContentfulBlocosNewsletter {
            ...FragmentBlocosNewsletter
          }
          ... on ContentfulBlocosFormularioDeContacto {
            ...FragmentBlocosFormularioDeContacto
          }
          ... on ContentfulBlocosContactos {
            ...FragmentBlocosContactos
          }
          ... on ContentfulBlocosUltimasNoticias {
            ...FragmentBlocosUltimasNoticias
          }
          ... on ContentfulBlocosCategoriasDeFormacao {
            ...FragmentBlocosCategoriasDeFormacao
          }
        }
      }
    }

    contentfulSite(node_locale: { eq: $locale }) {
      ...FragmentContentfulSite
    }

    site {
      ...FragmentSite
    }
  }
`
